import React, { useEffect } from "react"
import Page from "../../common/pages/Page"
import LoginOrRegisterContainer from "../containers/LoginOrRegister"
import { loginOrRegisterMeta } from "../../config/meta"
import { useRouter } from "next/router"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { googleRecaptcha } from "../../config/platforms"

const LoginOrRegister = (props) => {
  const router = useRouter()
  useEffect(() => {
    if (props?.loggedInUser?._id) {
      router.replace("/")
    }
  }, [props?.loggedInUser?._id, router])
  return (
    <Page {...loginOrRegisterMeta()}>
      <GoogleReCaptchaProvider useRecaptchaNet reCaptchaKey={googleRecaptcha}>
        <LoginOrRegisterContainer {...props} />
      </GoogleReCaptchaProvider>
    </Page>
  )
}

export default LoginOrRegister
