/*global FB*/
/*global gapi*/
import _ from "lodash"
import React, { useState, useEffect } from "react"
import { useMutation, useApolloClient } from "@apollo/client"
import {
  Input,
  Button,
  Flex,
  Heading,
  Text,
  Link,
  useTheme,
} from "@chakra-ui/react"
import Cell from "../../common/components/Cell"
import Grid from "../../common/components/Grid"
import Card from "../../common/components/Card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons"
import { appleSignIn } from "../../config/platforms"
import { getQueryParam } from "../../utilities/queryParams"
import {
  authenticateSocialMutate,
  manualLoginMutate,
  manualRegisterMutate,
} from "../graphql/users"
import { validateEmail } from "../../utilities/email"
import { Box } from "@chakra-ui/react"
import { useRouter } from "next/router"
import styles from "./css/Login.module.css"
import YoutubeModal from "../../common/components/YoutubeModal"
import AppDownloadButtons from "../../common/components/AppDownloadButtons"
import NextLink from "next/link"
import Script from "next/script"
import { useGoogleLogin } from "@react-oauth/google"
import useColors from "../../common/hooks/useColors"

const AppleButton = "/images/appleid_button.png"

function validateLogin({
  loginObj: { firstName, lastName, email, password },
  isLogin,
}) {
  let errorStr = ""
  if (firstName.length < 2 && !isLogin) {
    errorStr += " Your first name must be at least 2 letters long."
  }
  if (lastName.length < 2 && !isLogin) {
    errorStr += " Your last name must be at least 2 letters long."
  }
  if (validateEmail(email) === false) {
    errorStr += " Your email does not seem to be a valid email."
  }
  if (password.length < 6) {
    errorStr += " Your password must be at least 6 characters long."
  }
  return errorStr
}

const FBLoginPromise = () => {
  return new Promise((resolve, reject) => {
    FB.login(
      function (response) {
        resolve(response)
      },
      { scope: "public_profile,email" },
    )
  })
}

async function socialLogin({
  platform,
  authenticateSocial,
  auth,
  firstName,
  lastName,
  picture,
}) {
  if (platform === "facebook") {
    auth = await FBLoginPromise()
  }
  const resp = await authenticateSocial({
    variables: {
      platform,
      token: auth.authResponse.accessToken,
      firstName,
      lastName,
      picture,
      isWeb: true,
    },
  })
  const {
    data: {
      authenticateSocial: { token, error },
    },
  } = resp
  if (error) {
    throw new Error(error)
  } else {
    return token
  }
}

async function loginManually({
  manualLogin,
  manualRegister,
  loginObj,
  isLogin,
  recaptchaToken,
}) {
  const errorMessage = validateLogin({ loginObj, isLogin })
  if (errorMessage !== "") {
    throw new Error(errorMessage)
  }

  const { firstName, lastName, email, password, token: loginToken } = loginObj

  if (isLogin) {
    const resp = await manualLogin({
      variables: {
        credentials: {
          email,
          password,
          recaptchaToken,
          isWebsite: true,
        },
      },
    })
    const {
      data: {
        manualLogin: { token, error },
      },
    } = resp
    if (error) {
      throw new Error(error)
    }
    return token
  } else {
    const resp = await manualRegister({
      variables: {
        credentials: {
          firstName,
          lastName,
          email,
          password,
          token: loginToken,
          recaptchaToken,
          isWebsite: true,
        },
      },
    })
    const {
      data: {
        manualRegister: { token, error },
      },
    } = resp

    if (error) {
      throw new Error(error)
    }
    return token
  }
}

const LoginOrRegister = ({ location }) => {
  const { whiteColor } = useColors()
  const router = useRouter()
  const paramsAr =
    typeof window === "undefined"
      ? []
      : window.location.pathname.split("/login/")?.[1]?.split("/") ?? []
  const [token, email] = paramsAr
  const client = useApolloClient()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [isLogin, setIsLogin] = useState(!token && !email)
  const [errorMessage, setErrorMessage] = useState()
  const [isModalVideoOpen, setIsModalVideoOpen] = useState(false)
  const [modalVideoId, setModalVideoId] = useState()
  const [jsonToken, setJSONToken] = useState()
  const [loginObj, setLoginObj] = useState({
    firstName: "",
    lastName: "",
    email: email || "",
    password: "",
    token: token || null,
  })

  // passed back on successful apple signin
  const appleCode = getQueryParam("code", router)

  const [
    authenticateSocial,
    {
      loading: isLoadingSocial,
      // data: {},
      error: errorSocial,
    },
  ] = useMutation(authenticateSocialMutate)
  const [
    manualLogin,
    {
      loading: isLoadingManual,
      // data: {},
      error: errorLoginManual,
    },
  ] = useMutation(manualLoginMutate)
  const [
    manualRegister,
    {
      loading: isLoadingManualRegister,
      // data: {},
      error: errorRegisterManual,
    },
  ] = useMutation(manualRegisterMutate)

  const isLoading =
    isLoadingSocial || isLoadingManual || isLoadingManualRegister
  const error =
    errorMessage || errorSocial || errorLoginManual || errorRegisterManual

  useEffect(() => {
    if (!jsonToken) {
      return
    }
    const nextPath = router?.query?.returnUrl || "/"
    const nextPathname = nextPath === "/login" ? "/" : nextPath
    localStorage.setItem("jsonwebtoken", jsonToken)
    client.resetStore().then(() => {
      // wait for store to reset (if don't do this, then any redirects will not work)
      setTimeout(() => {
        router.push({
          pathname: nextPathname.replace("%3F", "?"),
        })
      }, 100)
    })
  }, [client, router, jsonToken, location])

  useEffect(() => {
    if (appleCode) {
      async function getToken() {
        const jTok = await socialLogin({
          platform: "apple",
          authenticateSocial,
          auth: {
            authResponse: {
              accessToken: appleCode,
            },
          },
        })
        setJSONToken(jTok)
      }
      getToken()
    }
  }, [appleCode, authenticateSocial])

  const login = useGoogleLogin({
    onSuccess: async (resp) => {
      const data = await fetch(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${resp?.access_token}`,
          },
        },
      )
      const profile = await data.json()
      try {
        const jTok = await socialLogin({
          platform: "google",
          authenticateSocial,
          auth: {
            authResponse: {
              accessToken: resp.access_token,
            },
          },
          firstName: profile.given_name,
          lastName: profile.family_name,
          picture: profile.picture,
        })
        setJSONToken(jTok)
      } catch (e) {
        setErrorMessage(
          "Something went wrong. Please contact support@pebbleclimbing.com.",
        )
      }
    },
  })

  return (
    <>
      <div className={styles["login-bkgrnd"]} />
      <div className={styles["login-cover"]} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxWidth={850}
        margin="auto"
        padding={5}
      >
        <Box
          mt={[0, -100]}
          display="flex"
          flexDirection={["column", "row", "row"]}
          alignItems="center"
          alignSelf="stretch"
          justifyContent="space-between"
          mb={[9, 0, 12]}
          p={3}
        >
          <Box
            position="relative"
            zIndex={10}
            display="flex"
            flexDirection="column"
            alignItems={["center", "flex-start", "flex-start"]}
            justifyContent="center"
            width="100%"
            maxWidth={450}
            mt={[6, 0, 0]}
          >
            <Heading
              as="h1"
              size={["xl", "2xl"]}
              width={["300px", "auto"]}
              lineHeight="1.2em"
              color={"#fff"}
              textShadow="0 0 4px rgb(0 0 0 / 50%);"
              textAlign={["center", "left", "left"]}
            >
              Gamify your climbing!
            </Heading>
            <Box pt={1} pb={8}>
              <Text
                as="p"
                color={"#fff"}
                textShadow="0 0 4px rgb(0 0 0 / 50%);"
                textAlign={["center", "left", "left"]}
                mr={[0, 0, 5]}
              >
                Focus your climbing sessions by using your sends to complete
                games. Play alone or compete with your friends!
              </Text>
            </Box>
            <Box mt={0} mb={[5, 0, 0]}>
              <AppDownloadButtons isCentered />
            </Box>
          </Box>
          <Card mt={[3, 0]} width={390} maxWidth="calc(100vw - 55px)">
            {!token && (
              <>
                <Flex align={"stretch"} direction="column">
                  <Button
                    variant="facebook"
                    onClick={async () => {
                      try {
                        const jTok = await socialLogin({
                          platform: "facebook",
                          authenticateSocial,
                        })
                        setJSONToken(jTok)
                      } catch (e) {
                        setErrorMessage(e)
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      color={whiteColor}
                      icon={faFacebook}
                    />
                    <Box as="span" ml={2}>
                      Login with Facebook
                    </Box>
                  </Button>
                  <Button variant="google" onClick={() => login()}>
                    <FontAwesomeIcon
                      color={whiteColor}
                      size="lg"
                      icon={faGoogle}
                    />
                    <Box as="span" ml={2}>
                      Login with Google
                    </Box>
                  </Button>
                  <Button
                    aria-label="Sign in with Apple"
                    variant="apple"
                    style={{
                      backgroundImage: `url(${AppleButton})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={async () => {
                      window.location = `https://appleid.apple.com/auth/authorize?response_type=code&client_id=${appleSignIn.clientID}&redirect_uri=${appleSignIn.redirectUri}`
                    }}
                  ></Button>
                </Flex>
                <Box mt={1} mb={3}>
                  {isLogin ? (
                    <Heading as="h5" size="sm" textAlign="center">
                      or login manually
                    </Heading>
                  ) : (
                    <Heading as="h5" size="sm" textAlign="center">
                      or sign up with your email address
                    </Heading>
                  )}
                </Box>
              </>
            )}
            <form
              onSubmit={async (event) => {
                event.preventDefault()
                try {
                  const recaptchaToken = await executeRecaptcha("login")
                  const jTok = await loginManually({
                    manualLogin,
                    manualRegister,
                    loginObj,
                    isLogin,
                    recaptchaToken,
                  })
                  setJSONToken(jTok)
                } catch (e) {
                  setErrorMessage(e.message)
                }
              }}
            >
              {!!token && (
                <Heading size="md">
                  Please enter in a password for your new account.
                </Heading>
              )}
              {isLogin ? null : (
                <Grid>
                  <Cell col={6} tablet={4} phone={4}>
                    <Input
                      onChange={({ target: { value: firstName } }) =>
                        setLoginObj({
                          ...loginObj,
                          firstName,
                        })
                      }
                      value={loginObj.firstName}
                      placeholder="First Name"
                    />
                  </Cell>
                  <Cell col={6} tablet={4} phone={4}>
                    <Input
                      mt={[-2, 0, 0]}
                      mb={3}
                      onChange={({ target: { value: lastName } }) =>
                        setLoginObj({
                          ...loginObj,
                          lastName,
                        })
                      }
                      value={loginObj.lastName}
                      placeholder="Last Name"
                    />
                  </Cell>
                </Grid>
              )}
              <Grid>
                <Cell
                  col={12}
                  display="flex"
                  alignItems="stretch"
                  flexDirection="column"
                  mb={3}
                >
                  <Input
                    type="email"
                    autoComplete={token ? "off" : "on"}
                    readOnly={token}
                    onChange={({ target: { value: email } }) =>
                      setLoginObj({
                        ...loginObj,
                        email,
                      })
                    }
                    value={loginObj.email}
                    placeholder="Email"
                    mb={3}
                  />
                  <Input
                    onChange={({ target: { value: password } }) =>
                      setLoginObj({
                        ...loginObj,
                        password,
                      })
                    }
                    autoComplete={token ? "off" : "on"}
                    placeholder="Password"
                    value={loginObj.password}
                    type="password"
                    mb={3}
                  />
                  {error ? (
                    <Heading as="h6" color="brand.orangeColor" size="sm">
                      {(error.message || error).split("\n").map((item, ind) => {
                        return (
                          <span key={ind}>
                            {item}
                            <br />
                          </span>
                        )
                      })}
                    </Heading>
                  ) : null}
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    alignSelf="stretch"
                    type="submit"
                  >
                    {isLoading
                      ? isLogin
                        ? "Logging in..."
                        : "Registering..."
                      : isLogin
                      ? "Login"
                      : "Register"}
                  </Button>
                </Cell>
              </Grid>
            </form>
            <Grid>
              <Cell col={12}>
                {isLogin ? null : (
                  <Heading as="h6" size="sm" textAlign="center">
                    By signing up for Pebble, you agree to our&nbsp;
                    <NextLink passHref href="/terms-of-use">
                      <Link>Terms of Use</Link>
                    </NextLink>{" "}
                    and{" "}
                    <NextLink passHref href="/privacy-policy">
                      <Link>Privacy Policy</Link>
                    </NextLink>
                  </Heading>
                )}
              </Cell>
            </Grid>
            {!token && (
              <Grid>
                <Cell col={12}>
                  <Heading
                    mb={3}
                    as="h6"
                    size="sm"
                    textAlign="center"
                  ></Heading>
                  <Flex align="center" justify="center">
                    <Heading as="h6" size="sm" textAlign={"right"}>
                      {isLogin ? (
                        <NextLink passHref href="/forgotpassword">
                          <Link>Forgot your password?</Link>
                        </NextLink>
                      ) : null}
                    </Heading>
                    <Box
                      ml={2}
                      pl={2}
                      mb={1}
                      borderLeftWidth={1}
                      borderColor="brand.headingLineColor"
                      height={"20px"}
                    />
                    <Heading as="h6" size="sm">
                      {isLogin ? (
                        <span>Not a Member?&nbsp;</span>
                      ) : (
                        <span>Already a Member?&nbsp;</span>
                      )}
                      <Link
                        onClick={() => {
                          setIsLogin(!isLogin)
                        }}
                      >
                        {isLogin ? <span>Register</span> : <span>Login</span>}
                      </Link>
                    </Heading>
                  </Flex>
                  <Heading mt={4} as="h6" size="sm" textAlign="center">
                    <Link
                      onClick={() => {
                        router.push("/gym-signup")
                      }}
                    >
                      Gym owners, add your gym!
                    </Link>
                  </Heading>
                </Cell>
              </Grid>
            )}
          </Card>
        </Box>

        <YoutubeModal
          isOpen={isModalVideoOpen}
          youtubeId={modalVideoId}
          closeModal={() => setIsModalVideoOpen(false)}
        />
      </Box>
      <div id="googleRecaptcha" />
      <Script
        src="https://connect.facebook.net/en_US/sdk.js"
        strategy="afterInteractive"
        onLoad={() => {
          try {
            window.FB.init({
              appId: "477495919416864",
              autoLogAppEvents: true,
              xfbml: true,
              version: "v2.7",
            })
          } catch (e) {
            console.error(e)
          }
        }}
      />
    </>
  )
}

// Connects React component to the redux store
// It does not modify the component class passed to it
// Instead, it returns a new, connected component class, for you to use.
export default LoginOrRegister
